// src/data/songs.js
const songs = [
    { title: "Nights Interlude", artist: "Nightmares On Wax" },
    { title: "Time Is the Enemy", artist: "Quantic" },
    { title: "Rainy Dayz", artist: "Ghostface Killah & Blue Raspberry" },
    { title: "Synthesizer (feat. George Clinton)", artist: "Outkast" },
    { title: "Ready or Not", artist: "Fugees" },
    { title: "Heaterz", artist: "Wu-Tang Clan" },
    { title: "Eye for a Eye", artist: "Mobb Deep" },
    { title: "Winter Warz", artist: "Ghostface Killah" },
    { title: "Walk With Me", artist: "Jedi Mind Tricks" },
    { title: "Blaze a 50", artist: "Nas" },
    { title: "Inmates to the Fire", artist: "Sunz Of Man" },
    { title: "Protect Ya Neck", artist: "Wu-Tang Clan" },
    { title: "Pit of Snakes", artist: "Gravediggaz" },
    { title: "Gold", artist: "GZA" },
    { title: "Wu-Gambinos", artist: "Wu-Tang Clan" },
    { title: "Double Drums", artist: "Peace Orchestra" },
    { title: "Deep Inside", artist: "Ours Samplus" },
    { title: "Knowledge God", artist: "Raekwon" },
    { title: "Journey to a Star", artist: "Lemongrass" },
    { title: "Wailin’", artist: "Outkast" },
    { title: "Twelve Jewelz", artist: "Gravedigaz" },
    { title: "Paradise", artist: "SomeHowArt" },
    { title: "Flying Birds", artist: "GZA" },
    { title: "Hell’s Wind Staff", artist: "GZA" },
    { title: "Liquid Swords", artist: "GZA" },
    { title: "The Arrival", artist: "Hell Razah" },
    { title: "Wu-Tang: 7th Chamber", artist: "Wu-Tang Clan" },
    { title: "Stay Golden", artist: "Jesse James" },
    { title: "Omid", artist: "Thievery Corporation" },
    { title: "Daily Routine", artist: "Ours Samplus" },
    { title: "Know the Ledge", artist: "Eric B. & Rakim" },
    { title: "Baise moi", artist: "Boztown" },
    { title: "Shining Star", artist: "Sunz Of Man" },
    { title: "Secrets", artist: "Andrew Applepie" },
    { title: "Hard to Forget (Moods remix)", artist: "Poldoore" },
    { title: "Geronimo vs. Buffalo Bill", artist: "Kognitif" },
    { title: "If I had you", artist: "Parov Stelar" },
    { title: "Penelope", artist: "4 Wings" },
    { title: "Samurai", artist: "Jazztronik" },
    { title: "Act 2", artist: "RJD2" },
    { title: "Chrome Wheels", artist: "Wu-Tang Clan" },
    { title: "Listen!!!", artist: "Taken Kweli" },
    { title: "Purple Haze", artist: "Jimi Hendrix" },
    { title: "Brothers and Sisters", artist: "Sixfingerz" },
    { title: "New World", artist: "Frenic" },
    { title: "Bug Powder Dust", artist: "Bomb the Bass" },
    { title: "Trife Life", artist: "Mobb Deep" },
    { title: "Alphabets", artist: "GZA" },
    { title: "When You Gonna Learn?", artist: "Mononome" },
    { title: "Herbal Essence", artist: "Hazy Rooms" },
    { title: "Ice Cream", artist: "Wu-Tang Clan" },
    { title: "Breezy", artist: "Eco Virtual" },
    { title: "Ms. Fat Booty", artist: "Mos Def" },
    { title: "Get Dis Money", artist: "Slum Village" },
    { title: "Love Sweet Sound", artist: "Groove Armada" },
    { title: "Raven", artist: "GoGo Penguin" },
    { title: "For Heavens Sake", artist: "Wu-Tang Clan" },
    { title: "Little Ghetto Boys", artist: "Wu-Tang Clan" },
    { title: "Cell Therapy", artist: "Goodie Mob" },
    { title: "Stakes Is High", artist: "De La Soul" },
    { title: "Nic’s Groove", artist: "Foreign Exchange" },
    { title: "Sound of da Police", artist: "KRS 1" },
    { title: "Glaciers of Ice", artist: "Ghostface Killah" },
    { title: "Razah’s Ladder", artist: "Blue Sky Black Death" },
    { title: "Don't Say Nuthin’", artist: "The Roots" },
    { title: "ATLiens", artist: "Outkast" },
    { title: "Don't Sweat The Technique", artist: "Eric B. & Rakim" },
    { title: "1nce Again", artist: "A Tribe Called Quest" },
    { title: "The State Of The Union", artist: "Thievery Corporation" },
    { title: "Magic Mustard Machine", artist: "PNFA" },
    { title: "Dungeon Sound", artist: "Gramatik" },
    { title: "Tearz", artist: "Wu Tang Clan" },
    { title: "The Guidelines", artist: "Aceyalone" },
    { title: "Stairway to Hip Hop Heaven", artist: "Led Zeppelin vs. Gramatik" },
    { title: "Alright", artist: "Frenik" },
    { title: "Blind Shot", artist: "Apanemic" },
    { title: "Friday", artist: "Gramatik" },
    { title: "Acid Raindrops", artist: "People Under The Stairs" },
    { title: "Drop", artist: "The Pharcyde" },
    { title: "This Love I’ve Met", artist: "Guggenz" },
    { title: "Les pensées", artist: "The Architect" },
    { title: "Igene", artist: "Suhov" },
    { title: "Two Dope Boyz (In a Cadillac)", artist: "Outkast" },
    { title: "Shadowboxin’", artist: "GZA" },
    { title: "The City", artist: "Wu-Tang Clan" },
    { title: "C.R.E.A.M.", artist: "Wu-Tang Clan" },
    { title: "It’s Yourz", artist: "Wu-Tang Clan" },
    { title: "The one", artist: "Boztown" },
    { title: "The Dub and the Restless", artist: "DJ Krush" },
    { title: "Roots", artist: "PNFA" },
    { title: "Fantasy Prison", artist: "Andrew Applepie" },
    { title: "Potholderz", artist: "Count Bass D ft. MF DOOM" },
    { title: "Intro", artist: "Killah Priest" },
    { title: "Decisions, Decisions", artist: "Goodie Mob" },
    { title: "The Red", artist: "Jaylib" },
    { title: "Speed", artist: "Little Brother" },
    { title: "Tried by 12", artist: "The East Flatbush Project" },
    { title: "You Wish", artist: "Nightmares on Wax" },
    { title: "Jazz Potato", artist: "Mr. Scruff" },
    { title: "The Meaning", artist: "The High & Mighty" },
    { title: "Every End Is a New Beginning", artist: "Mononome" },
    { title: "Downtown", artist: "Ours Samplus & Grandhuit" },
    { title: "Long Awaited", artist: "Lootpack" },
    { title: "Planet Rock", artist: "Afrika Bambaataa" },
    { title: "The Format", artist: "AZ" },
    { title: "Investigative Reports", artist: "GZA" },
    { title: "Poisonous Darts", artist: "Ghostface Killah" },
    { title: "Where Is the Morning Sun", artist: "SomehowArt" },
    { title: "Heartbox Intro", artist: "Suhov" },
    { title: "Valley of Kings", artist: "Sunz Of Man" },
    { title: "Life in the Rain", artist: "Quantic" },
    { title: "Valley of Kingz", artist: "Sunz of Man" },
    { title: "Danger", artist: "Erykah Badu" },
    { title: "Int’l Players Anthem (I Choose You)", artist: "UGK ft. Outkast" },
    { title: "Teen Pregnancy", artist: "Blank Banshee" },
    { title: "Goofy Zones", artist: "Blank Banshee" },
    { title: "808 Dreams", artist: "VAPERROR" },
    { title: "Starstruck", artist: "Minus 8" },
    { title: "The Words", artist: "Emanon" },
    { title: "Devil’s Gotta Run", artist: "Ernesto" },
    { title: "Black Steel In The Hour Of Chaos", artist: "Public Enemy" },
    { title: "Thieves In The Night", artist: "Black Star" },
    { title: "Keep On", artist: "Dwele" },
    { title: "Personality", artist: "Virgin Souls" },
    { title: "Hell on Earth", artist: "Mobb Deep" },
    { title: "Much More", artist: "De La Soul" },
    { title: "Pillage", artist: "Cappadonna" },
    { title: "Vibe Vendetta", artist: "Pretty Lights" },
    { title: "Invincible", artist: "Capone-N-Noreaga" },
    { title: "Lyrical Swords", artist: "Ras Kass" },
    { title: "Shook Ones", artist: "Mobb Deep" },
    { title: "Domination", artist: "Peter Kruder" },
    { title: "Super Lyrical", artist: "Big Pun & Black Thought" },
    { title: "The Story of O.J.", artist: "Jay-Z" },
    { title: "Verses", artist: "Wu-Tang Clan" },
    { title: "Clouds", artist: "IHF" },
    { title: "Napalese Bliss (File Brazillia mix)", artist: "The Irresistible Force" },
    { title: "You Used to Love Me", artist: "Denita & Quantic" },
    { title: "All I Ask", artist: "Rae & Christian ft. Veba" },
    { title: "Bronx Theme", artist: "DJ Cam" },
    { title: "Natural High", artist: "Tosca" },
    { title: "Exhibit C", artist: "Jay Electronica" },
    { title: "Don't Ever Change", artist: "SomeHowArt" },
    { title: "Brokeland", artist: "Kirk Knight" },
    { title: "Black Baby(DJ Kicks)", artist: "Kruder & Dorfmeister" },
    { title: "Auditorium", artist: "Mos Def ft. Slick Rick" },
    { title: "Incarcerated Scarfaces", artist: "Raekwon" },
    { title: "Bring The Pain", artist: "Method Man" },
    { title: "Amplified Sample", artist: "GZA" },
    { title: "Assassination Day", artist: "Raekwon, Inspektah Dek, & RZA" },
    { title: "Da Joint", artist: "EPMD" },
    { title: "Open Mic Night", artist: "The High & Mighty" },
    { title: "Battle of 2001", artist: "Cypress Hill" },
    { title: "Live From New York", artist: "Raekwon" },
    { title: "Black Chocolate", artist: "Kristian Shek" },
    { title: "Error 404", artist: "Ours Samplus & Grandhuit" },
    { title: "Seasons", artist: "CunninLyguists ft. Masta Ace" },
    { title: "Harmonicas Are Shite", artist: "Fila Brazillia" },
    { title: "Herb Garden", artist: "BluntOne ft. The Deli" },
    { title: "Good Mourning", artist: "Reflection Eternal" },
    { title: "Les Nuits", artist: "Nightmares on Wax" },
    { title: "Drop a Gem on ‘Em", artist: "Mobb Deep" },
    { title: "No Said Date", artist: "Masta Killa" },
    { title: "Shook Ones, Part II", artist: "Mobb Deep" },
    { title: "GAZILLION EAR", artist: "MF DOOM" },
    { title: "The Bully Plank", artist: "Paper Tiger" },
    { title: "Goodie Bag", artist: "Goodie Mob" },
    { title: "Everything Electric", artist: "Frenic" },
    { title: "Love Can Damage Your Health", artist: "Télépopmusik" },
    { title: "Ferrari Saga", artist: "Curren$y" },
    { title: "Yellow Bird", artist: "Pretty Lights" },
    { title: "What a Job", artist: "Devin the Dude" },
    { title: "Wrong Platform", artist: "Pretty Lights" },
    { title: "Dusty Memories", artist: "Ours Samplus" },
    { title: "Actin Crazy", artist: "Action Bronson" },
    { title: "Army of Me", artist: "Björk" },
    { title: "The Foundation", artist: "Xzibit" },
    { title: "Metabolism Yo", artist: "Yo - RLLBTS" },
    { title: "Content in Context", artist: "HOLLANDJUNGLE" },
    { title: "Pride", artist: "Killah Priest" },
    { title: "From Then 'Til Now", artist: "Killah Priest" },
    { title: "Living In the World Today", artist: "GZA" },
    { title: "Stay Fly", artist: "Three 6 Mafia" },
    { title: "Swing Blades", artist: "Jean Grae ft. Cannibal Ox" },
    { title: "Anti-Matter", artist: "King Geedorah ft. Mr. Fantastik" },
    { title: "Trippin", artist: "Zion I" },
    { title: "The Sickness", artist: "J Dilla ft. Nas" },
    { title: "Keep It On", artist: "Slum Village" },
    { title: "Speed Law", artist: "Mos Def" },
    { title: "Kashmir Day Trip (Kuba Remix)", artist: "Entheogenic" },
    { title: "Discipline 99 Pt.1", artist: "Quasimoto ft. Wildchild" },
    { title: "You Never Knew", artist: "Hieroglyphics" },
    { title: "You Shall Rise", artist: "Mononome" },
    { title: "Inhale", artist: "The East Flatbush Project" },
    { title: "Sniper Elite", artist: "MF Doom" },
    { title: "Crate Diggin’", artist: "Lootpack" },
    { title: "Dreams", artist: "Tame One" },
    { title: "The Good, The Bad, The Ugly", artist: "Consequence" },
    { title: "Doot Doo Dah Da", artist: "Dice Raw" },
    { title: "Break My Heart in 2", artist: "Ancient Astronauts" },
    { title: "Only Yesterday", artist: "Pretty Lights" },
    { title: "The Type", artist: "the Alchemist" },
    { title: "Opening", artist: "Parker's Music" },
    { title: "No Joke" , artist: "Jay Rock" },
    { title: "Fedime’s Flight", artist: "Jazzanova" },
    { title: "Accordion", artist: "Madvillain" },
    { title: "Za’atar Smoke", artist: "The Alchemist" },
    { title: "We Must Go On", artist: "Pretty Lights" },
    { title: "Marvin’s Mood", artist: "Stro Elliot" },
    { title: "Forgive (A Man)", artist: "Mononome" },
    { title: "Heavenly Divine", artist: "Jedi Mind Tricks" },
    { title: "Ground & Water", artist: "Damu The Fudgemunk" },
    { title: "Persona", artist: "Koralle & Kuranes" },
    { title: "It’s Complete", artist: "Atjazz" },
    { title: "Post Requisite", artist: "Flying Lotus" },
    { title: "Living", artist: "Lil Skate" },
    { title: "Kiara", artist: "Bonobo" },
    { title: "Sheràn", artilst: "Mononome" },
    { title: "Rather Be Myself", artist: "Russ" },
    { title: "Timeless", artist: "Illa J" },
    { title: "Shine", artist: "The Alchemist"}
    ];
  
  export default songs;
  